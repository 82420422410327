@import url('bootstrap/dist/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.App {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
  text-align: center;
}

.content {
  flex: 1;
}